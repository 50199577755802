// mixpanel metrics enum
// category
const METRIC_CATEGORY = {
    DASHBOARD: 'Risk Dashboard',
    RESOURCES: 'Resources',
    RESOURCE: 'Resource',
    COMPLIANCE: 'Compliance',
    CLOUD_ACCOUNTS: 'Cloud Accounts',
    SETTINGS: 'Settings',
    CVE: 'CVE',
    INIT: 'Init',
    DOWNLOAD: 'Download',
    INSTRUCTION: 'Instruction',
    PAGEVIEW: 'Pageview',
    LOGIN: 'Login',
    SIGNUP: 'Signup',
    LOGOUT: 'Logout',
    FORGOT_PASSWORD: 'Forgot password',
    FORGOT_TENANT: 'Forgot tenant',
    RESEND_INVITE: 'Resend invite',
    CHANGE_TENANT: 'Change tenant',
    PASSWORD_VISIBILITY: 'Password visibility',
    RESEND_VERIFICATION_EMAIL: 'Resend verification email',
    CHANGE_PASSWORD: 'Password changed',
    INVITED: 'Invited',
    INVITE_USER: 'Invite new user',
    USER: 'User',
    CREDENTIAL: 'Credential',
    USER_PROFILE: 'User profile',
    TRAFFIC_MIX: "Traffic mix",
    PRIVACY_POLICY: "Privacy policy",
    EMAIL_VERIFIED: 'Email verified',
    TRY_UPGRADE_ACCOUNT: 'Try Upgrading Account'
}

// action
const METRIC_ACTION = {
    VIEWED: 'Viewed',
    UPDATED: 'Updated',
    CREATED: 'Created',
    DELETED: 'Deleted',
    CLICKED: 'Clicked',
    DEEPLINK_CLICKED: 'Deep Link',
    SEARCH: 'Search',
    INIT: 'Init',
    DOWNLOAD: 'download',
    COPIED: 'copied',
    VISITED: 'visited',
    BUTTON_CLICKED: 'Button clicked',
    INVITED: 'invited',
    CONNECTED: 'connected',
    REVOKED: 'revoked',
    GENERATED: 'generated'
}

// message
const METRIC_DETAIL = {
    SUCCESS: 'successfully',
    INIT: 'initialized',
    FAILED: 'failed'
}

module.exports = {
    METRIC_CATEGORY: METRIC_CATEGORY,
    METRIC_ACTION: METRIC_ACTION,
    METRIC_DETAIL: METRIC_DETAIL
};