/**
 * Products
 */
const PRODUCT = {
    UAP: 'uap',
    XPROTECT: 'xprotect',
    XSHIELD: 'xshield',
    XCLOUD: 'xcloud'
}

export default PRODUCT;