import Router from "next/router";
import { NG_ROUTES_MAP } from "src/constants";

const SELF_SIGNUP = "SELF-SIGNUP";
const SIGNUP_URL = "/xcloud/auth/signup";
const LOGIN_URL = "/auth/login";

function handleRouteChange(url, config) {
  if (!config) {
    return;
  }
  if(url === SIGNUP_URL && config.DISABLED_FEATURES.includes(SELF_SIGNUP)) {
    Router.push(LOGIN_URL);
    Router.events.emit("routeChangeError");
    throw "routeChange aborted.";
  }
  const URLData = new URL(url, `https://${config.HOST_URL}`);
  let path = URLData.pathname.split("/");
  let route = `/${path[1]}`;
  let homeRoute = NG_ROUTES_MAP[`${route}${path[2] ? `/${path[2]}` : ""}`];
  if (homeRoute) {
    path.shift();
    path[0] = homeRoute;
    path = path.join("/");
    let updatedRoute = `${config.NG_APP_URL}${path}${URLData.search}`;
    Router.push(updatedRoute);
    Router.events.emit("routeChangeError");
    throw "routeChange aborted.";
  }
}

const RouteHandler = {
  handleRouteChange
};

export default RouteHandler;
