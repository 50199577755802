/*
 * ------------------------
 * File: /src/utils/database/database.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

import { sendRequest } from "utils/networking/HttpHelper";
import _ from "underscore";

import APIHandler from "utils/networking/APIHandler";

let auth;

async function setupFirebase() {
  const setupAuth = () => {
    auth = firebase.auth();
  };
  if (!firebase.apps.length) {
    let config = await APIHandler.getConfig();
    const firebaseConfig = {
      apiKey: config.FIREBASE_PUBLIC_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      databaseURL: config.FIREBASE_DATABASE_URL,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config.FIREBASE_MESSAGING_SENDERID
    };
    if (!firebaseConfig || !firebaseConfig.apiKey) {
      return Promise.resolve("Server side");
    }
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    setupAuth();
  } else {
    setupAuth();
  }
}

async function logFirebaseEvent(event, data = {}) {
  let analytics = firebase.analytics();
  analytics.logEvent(event, data);
}

async function createUserWithEmailAndPassword(email, password) {
  await setupFirebase();
  return auth.createUserWithEmailAndPassword(email, password);
}

async function signInWithEmailAndPassword(email, password) {
  await setupFirebase();
  return auth.signInWithEmailAndPassword(email, password);
}

async function signInWithGithub() {
  await setupFirebase();
  var provider = new firebase.auth.GithubAuthProvider();
  return auth.signInWithPopup(provider);
}

async function signInWithTwitter() {
  await setupFirebase();
  var provider = new firebase.auth.TwitterAuthProvider();
  provider.setCustomParameters({
    display: "popup"
  });
  return auth.signInWithPopup(provider);
}

async function signInWithGoogle() {
  await setupFirebase();
  var provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
}

async function signInWithFacebook() {
  await setupFirebase();
  var provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("email");
  return auth.signInWithPopup(provider);
}

async function forgotPassword(email, tenantName) {
  return APIHandler.forgotPassword({ email, tenantName });
}

async function sendEmailVerification(actionCode) {
  await setupFirebase();
  if (!auth.currentUser) {
    return Promise.resolve();
  }
  return APIHandler.sendEmailVerification(actionCode);
}

var currentUser;
async function getCurrentUser(force) {
  if (currentUser && !force) {
    return currentUser;
  }
  await setupFirebase();
  currentUser = firebase.auth().currentUser;
  return currentUser;
}


async function getCurrentUserPromise() {
  currentUser = await getCurrentUser();
  if (!currentUser) {
    return new Promise((resolve, reject) => {
      let unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        if (user) {
          currentUser = user;
          resolve(currentUser);
        } else {
          reject(new Error("Not logged in"));
        }
      });
    });
  }
  return Promise.resolve(currentUser);
}

var token;
async function getCurrentUserToken(force) {
  let currentUser = await getCurrentUser(force);
  if (!currentUser) {
    if (force) {
      currentUser = await getCurrentUserPromise();
    }
    if (!currentUser) {
      throw new Error("No current user");
    }
  }
  if (token && !force) {
    return token;
  }
  token = await currentUser.getIdToken(force || false);
  return token;
}

function getUserData(full = false) {
  let query = {};
  if (full) {
    query.details = true
  }
  return new Promise((resolve, reject) => {
    sendRequest(
      "/user",
      "get",
      query,
      profile => {
        resolve(profile);
      },
      {
        requiresAuth: true,
        errorCallback: reject,
        dontShowMessage: true
      }
    );
  });
}

function getFullUserData() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/user?details=true",
      "get",
      {},
      profile => {
        resolve(profile);
      },
      {
        requiresAuth: true,
        errorCallback: reject,
        dontShowMessage: true
      }
    );
  });
}

function saveUserProfile(profile) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/user",
      "post",
      profile,
      () => {
        resolve(profile);
      },
      {
        requiresAuth: true,
        errorCallback: reject,
        dontShowMessage: true
      }
    );
  });
}

async function signOut() {
  
  return true
}

async function updateEmailVerificationStatus(uid, status, source = "web") {
  await setupFirebase();
  return firebase
    .database()
    .ref("verification/" + uid)
    .set({
      emailVerified: status,
      source
    });
}

async function checkEmailActionCode(code, userID) {
  await setupFirebase();
  let config = await APIHandler.getConfig();
  return APIHandler.verifyEmail({
    oobCode: code,
    apiKey: config.FIREBASE_PUBLIC_API_KEY,
    tenantId: auth.tenantId,
    userID,
    lang: "en" //TODO use navigator language
  });
}

async function verifyPasswordResetCode(code) {
  await setupFirebase();
  return auth.verifyPasswordResetCode(code);
}

async function confirmPasswordReset(code, newPassword) {
  await setupFirebase();
  return auth.confirmPasswordReset(code, newPassword);
}

async function updatePassword(newPassword) {
  await setupFirebase();
  return auth.currentUser.updatePassword(newPassword);
}

async function setTenantId(tenantId) {
  await setupFirebase();
  auth.tenantId = tenantId;
}

let database = {
  createUserWithEmailAndPassword: createUserWithEmailAndPassword,
  signInWithEmailAndPassword: signInWithEmailAndPassword,
  signInWithGithub: signInWithGithub,
  signInWithTwitter: signInWithTwitter,
  signInWithGoogle: signInWithGoogle,
  forgotPassword: forgotPassword,
  signInWithFacebook,
  getCurrentUser: getCurrentUser,
  getCurrentUserToken: getCurrentUserToken,
  getUserData: getUserData,
  signOut: signOut,
  saveProfile: saveUserProfile,
  sendEmailVerification,
  updateEmailVerificationStatus,
  currentUser: getCurrentUserPromise,
  checkEmailActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  setTenantId,
  getFullUserData,
  logFirebaseEvent,
  updatePassword
};

export default database;
