// pages/_app.js
import { NoSsr, Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "src/context/theme/theme";
import TitleProvider from "src/context/title/title";
import App from "next/app";
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@components/Common/Snackbar";
import {
  DB_KEYS,
  LOCAL_STORAGE_KEYS,
  USER_STATUS,
  PROVIDERS,
  ROLES,
  PARTNER_USER_TYPES,
  PAID_USER_TYPES
} from "src/constants";
import database from "src/utils/database/database";
import analytics from "src/utils/analytics/analytics";
import { loadCSS } from "fg-loadcss";
import Router from "next/router";
import numeral from "numeral";
import "src/App.css";
import "src/App_responsive.css";
import "../src/App_example_sass.scss";
//import "src/style.scss";
import "reports-frontend/lib/index.css";
import "rc-slider/assets/index.css";
import "react-grid-layout/css/styles.css";
import "react-sortable-tree/style.css";
import * as Sentry from "@sentry/browser";
import APIHandler from "src/utils/networking/APIHandler";
import RouteHandler from "src/utils/routeHandler/RouteHandler";
import Metrics from "src/utils/metrics/metrics";
import { AUTH_STATES } from "../src/constants";

let lightSchemeIcon;
let darkSchemeIcon;
let matcher;

async function onUpdate() {
  if (matcher.matches) {
    !lightSchemeIcon &&
      (lightSchemeIcon = document.querySelector("link#light-scheme-icon"));
    lightSchemeIcon && lightSchemeIcon.remove();
    document.head.append(darkSchemeIcon);
  } else {
    !darkSchemeIcon &&
      (darkSchemeIcon = document.querySelector("link#dark-scheme-icon"));
    document.head.append(lightSchemeIcon);
    darkSchemeIcon && darkSchemeIcon.remove();
  }
}

function listenForDayNight() {
  lightSchemeIcon = document.querySelector("link#light-scheme-icon");
  darkSchemeIcon = document.querySelector("link#dark-scheme-icon");
  matcher = window.matchMedia("(prefers-color-scheme: dark)");
  matcher.addListener(onUpdate);
  onUpdate();
}

function initializeSentry(dsn, version) {
  console.log("Configuring sentry");
  if (dsn) {
    window.captureException = (error, level) => {
      if (
        !Sentry.getCurrentHub().getClient() ||
        !Sentry.getCurrentHub()
          .getClient()
          .getOptions().enabled
      ) {
        Sentry.init({
          dsn: dsn
        });
      }
      Sentry.withScope(scope => {
        scope.setLevel(level);
        scope.setTag("version", version);
        Sentry.captureException(error);
      });
    };
    const env = process.env.NODE_ENV;
    if (env !== "development") {
      let originalConsoleError = console.error;
      console.error = error => {
        window.captureException(error, "error");
        // eslint-disable-next-line no-undef
        originalConsoleError.apply(console, arguments);
      };
    }
  }
}

export default class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      checkingCache: true,
      showBlockedPopup: false,
      loading: false,
      tenantsList: null
    };
    this.initializedGlobals = false;
    this.snackbarRef = React.createRef();
  }

  setUser = async user => {
    return new Promise(resolve => [
      this.setState(
        {
          user: user,
          isLoggedIn: user != null,
          loading: false
        },
        resolve
      )
    ]);
  };

  refreshUser = async (retry = true, url) => {
    if (url && url.includes("/user")) {
      return this.handleMisConfiguredUser();
    }
    try {
      if (this.props.authState === AUTH_STATES.LoggedIn) {
        await this.getUserProfile(AuthUser);
      } else{
        retry && setTimeout(this.refreshUser, 1000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  hasSignupType(user) {
    if (user?.signupType === 0 || user?.signupType) {
      return true;
    }
    return false;
  }

  getUserProfile = async (auth, logout, updateCookie) => {
    if(typeof window === "undefined") {
      return;
    }
    if(logout && !window.securevia.logout) {
      window.securevia.logout = logout;
    }
    if(updateCookie && !window.securevia.updateCookie) {
      window.securevia.updateCookie = updateCookie;
    }
    if(auth.authState !== AUTH_STATES.LoggedIn) {
      return;
    }

    try {
      let user;
      let initUser = false;
      try {
        if (this.state.user) {
          user = await window.securevia.database.getUserData(true);
          user = Object.assign({}, this.state.user, user);
        } else {
          user = await window.securevia.database.getFullUserData(true);
          initUser = true;
        }
        let notifications = {};
        notifications = await APIHandler.getUserNotifications();
        user = {...user, ...notifications};        
        user.profilePicURL = user.profilePicURL;
        if (user.status !== USER_STATUS.BLOCKED) {
          let tenantDetails;
          try {
            tenantDetails = await APIHandler.getTenantDetails();
            this.getTenantsList();
          } catch (e) {}
          user.tenantDetails = tenantDetails;
        }
      } catch (e) {
        if (e.status === 403) {
          return this.handleMisConfiguredUser();
        }
      }
      this.setState({ loading: false });
      if (user) {
        if (user.status === USER_STATUS.BLOCKED) {
          return this.handleUnsubscribedUser(user);
        }
        if (!user.status) {
          return this.handleMisConfiguredUser(user);
        }

        let localUser = this.createUserData(user);

        window.localStorage.setItem(
          DB_KEYS.USER_PROFILE_KEY,
          JSON.stringify(localUser)
        );
        this.setUser(user);
      }
      let path = Router.asPath;
      let nextPath = this.routeAfterLogin();
      this.saveLastUserDetails(user);
      if (path.includes("/auth/login")) {
        if (initUser) {
          window.NotificationUtils.showSuccess("Welcome Back!");
        }
        this.routeIfRequired(path, nextPath);
      }

      if (user && path.includes("/auth/signup")) {
        if (user.role !== ROLES.VIEWER && !nextPath) {
          nextPath = "/xcloud/dashboard";
        }
        this.routeIfRequired(path, nextPath);
      }
    } catch (e) {
      console.error(e.message);
      this.handleMisConfiguredUser()
    }
  };

  getAccountType(signupType) {
    if (PARTNER_USER_TYPES.includes(signupType)) {
      return "Partner";
    }
    if (PAID_USER_TYPES.includes(signupType)) {
      return "Advanced";
    }
    return "Standard";
  }

  getUserRole = () => {
    return this.state?.user?.role;
  };

  updateNotifications = async () => {
    try {
      let notifications = await APIHandler.getUserNotifications();
      let updatedUser = {...this.state.user, ...notifications};
      this.setUser(updatedUser);
    }
    catch(e) {
      console.error(e);
    }
  }

  saveLastUserDetails(user) {
    let lastUser = window.localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_USER);
    if (lastUser) {
      lastUser = JSON.parse(lastUser);
      if (lastUser?.email === user?.email && !user.signupType) {
        user.signupType = lastUser.signupType;
      }
    }

    let userDataToStore = {
      email: user.email,
      signupType: user.signupType,
      hasResources: user.hasResources,
      role: user.role
    };
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_USER,
      JSON.stringify(userDataToStore)
    );
  }

  createUserData(user) {
    return {
      uid: user.uid,
      tenantID: user.tenantID,
      tenantName: user.tenantName,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      createdTime: user.createdTime,
      updatedTime: user.updatedTime,
      status: user.status,
      role: user.role,
      customerID: user.customerID,
      productCode: user.productCode,
      isInvitedUser: user.isInvitedUser,
      invitedTime: user.invitedTime,
      provider: user.provider,
      socialProvider: user.socialProvider
    };
  }

  routeIfRequired(path, nextPath) {
    if (window.securevia?.pauseRouting) {
      return;
    }
    if (nextPath === path) {
      nextPath = "/xcloud";
    }
    Router.replace(nextPath);
  }

  handleUnsubscribedUser = user => {
    if (user.provider === PROVIDERS.AWS) {
      this.showUnsubscribeErrorPopup();
    } else {
      this.showBlockedErrorPopup();
    }
    window.localStorage.clear();
    Router.push("/xcloud/logout");
  };

  showUnsubscribeErrorPopup = () => {
    this.setState({
      showBlockedPopup: true,
      provider: PROVIDERS.AWS
    });
  };

  showBlockedErrorPopup = () => {
    this.setState({
      showBlockedPopup: true,
      provider: null
    });
  };

  hideBlockedErrorPopup = () => {
    this.setState({
      showBlockedPopup: false,
      provider: null
    });
  };

  handleMisConfiguredUser = () => {
    window.NotificationUtils.showError(
      "Something went wrong, please try again"
    );
    window.localStorage.clear();
    Router.push("/xcloud/logout");
  };

  clearLocalStorage() {
    let tenantId = window.localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID);
    let lastUser = window.localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_USER);

    window.localStorage.clear();

    if (tenantId) {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TENANT_ID, tenantId);
    }
    if (lastUser) {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_USER, lastUser);
    }
  }

  routeAfterLogin() {
    let path = Router.asPath;
    let nextPath = "";

    try {
      let url = window.location.href;
      let searchParams = new URLSearchParams(url.substring(url.indexOf("?")));
      nextPath = searchParams.get("route");
    } catch (e) {
      console.error(e);
    }

    let urlParams = new URLSearchParams({
      route: nextPath
    });
    if (nextPath) {
      if (nextPath[0] === "/") {
        path = nextPath;
      } else {
        path = "/" + nextPath;
      }
    }

    return path || "/";
  }

  gotoHome = e => {
    e.preventDefault && e.preventDefault();
    Router.replace("/xcloud/public");
  };

  async initializeGlobals() {
    if (this.initializedGlobals || typeof window === "undefined") {
      return;
    }

    this.initializedGlobals = true;

    window.securevia = {};
    window.securevia.database = database;
    window.securevia.analytics = analytics;
    window.gotoHome = this.gotoHome;
    window.securevia.metrics = Metrics;

    window.securevia.refreshUser = this.refreshUser;
    window.securevia.getAccountType = this.getAccountType;
    window.securevia.updateUser = this.updateUser;
    window.securevia.userRole = this.getUserRole;
    window.securevia.refreshNotifications = this.updateNotifications;
    window.securevia.triggerDiscovery = APIHandler.triggerDiscovery;
    window.securevia.getTenantsList = this.getTenantsList;
    this.setLoginManager();

    window.NotificationUtils = {};

    window.DisplayUtils = {};

    window.DisplayUtils.FormatNumber = (number, format = "0,0") => {
      return numeral(number).format(format);
    };

    window.NotificationUtils.showSuccess = (message, extras = {}) => {
      if (!this.snackbarRef.current) {
        return setTimeout(() => {
          window.NotificationUtils.showSuccess(message, extras);
        }, 500);
      }
      this.snackbarRef.current &&
        this.snackbarRef.current.showSuccess(message, extras);
    };
    window.NotificationUtils.showError = (message, extras = {}) => {
      if (!this.snackbarRef.current) {
        return setTimeout(() => {
          window.NotificationUtils.showError(message, extras);
        }, 500);
      }
      this.snackbarRef.current &&
        this.snackbarRef.current.showError(message, extras);
    };
    window.NotificationUtils.showWarning = (message, extras = {}) => {
      if (!this.snackbarRef.current) {
        return setTimeout(() => {
          window.NotificationUtils.showWarning(message, extras);
        }, 500);
      }
      this.snackbarRef.current &&
        this.snackbarRef.current.showWarning(message, extras);
    };
    window.NotificationUtils.showInfo = (message, extras = {}) => {
      if (!this.snackbarRef.current) {
        return setTimeout(() => {
          window.NotificationUtils.showInfo(message, extras);
        }, 500);
      }
      this.snackbarRef.current &&
        this.snackbarRef.current.showInfo(message, extras);
    };

    

    let config = await APIHandler.getConfig();
  
    initializeSentry(config.SENTRY_DSN, config.VERSION);
    console.info("Version", config.VERSION);
    console.info("Build", config.BUILD);

    this.setState({
      config
    });

    RouteHandler.handleRouteChange(Router.asPath, config);
    listenForDayNight();
    this.initMetrics();
  }

  async getTenantsList() {
    try {
      let tenantsList = await APIHandler.getTenantsList();
      let list = tenantsList.tenants;

      list = list.filter((tenant) => {
        for(let product of tenant.products) {
          if(product.productName === "xcloud") {
            return tenant
          }
        }
      })
      tenantsList.tenants = list
      this.setState({
        tenantsList 
      })
    }
    catch(e) {
      console.error(e);
    }
  }

  setLoginManager() {
    window.securevia.LoginManager = {
      isLoggedIn: () => {
        return database.getCurrentUser() != null;
      },
      token: database.getCurrentUserToken,
      logout: database.signOut
    };
  }

  handleRouteChange = url => {
    RouteHandler.handleRouteChange(url, this.state.config);
    window.securevia.metrics.recordRouteChange(url);
  };

  async componentDidMount() {
    Router.events.on("routeChangeStart", this.handleRouteChange);

    let storedUser = window.localStorage.getItem(DB_KEYS.USER_PROFILE_KEY);
    let user;
    try {
      user = JSON.parse(storedUser);
    } catch (e) {
      user = null;
    }
    if (user) {
      this.setState({
        user,
        AuthUser: {
          uid: user.uid,
          profilePicURL: user.profilePicURL,
          displayName: user.firstName
        }
      });
    }
    this.setState({
      checkingCache: false
    });

    this.removeStyles();

    await this.refreshUser(false);

    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    this.initMetrics();
  }

  componentWillUnmount() {
    Router.events.off("routeChangeStart", this.handleRouteChange);
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  removeStyles() {
    try {
      const jssStyles = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    } catch (_) {}
  }

  renderUnsubscribedPopup() {
    return (
      <Dialog
        open={this.state.showBlockedPopup}
        onClose={this.hideBlockedErrorPopup}
        aria-labelledby="unsubscribed-notification"
      >
        <DialogTitle id="unsubscribed-notification-title">
          {this.state.provider === PROVIDERS.AWS
            ? "User Not Subscribed"
            : "User Blocked"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.provider === PROVIDERS.AWS
              ? "Unable to login since you have unsubscribed from ColorTokens. You need to resubscribe from AWS Marketplace to continue."
              : "Unable to login since you have been blocked from ColorTokens. Sign up to be a user again."}
          </DialogContentText>
          <DialogActions>
            <Button onClick={this.hideBlockedErrorPopup}>Close</Button>
            {this.state.provider === PROVIDERS.AWS && (
              <a
                id="market-resubscribe-link"
                href={this.state.config?.AWS_MARKET_LINK || ""}
              >
                <Button variant="contained" color="primary">
                  Resubscribe
                </Button>
              </a>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  initMetrics() {
    let userData = this.props?.pageProps?.userProfile;
    window.securevia.metrics.init(userData);
  }

  updateUser = details => {
    let user = Object.assign(this.state.user, details);
    this.setState({
      user
    });
  };

  render() {
    const { Component, pageProps } = this.props;
    pageProps.userProfile = this.state.user;
    pageProps.fetchUser = this.getUserProfile;
    pageProps.tenantsList = this.state.tenantsList;
    this.initializeGlobals();

    return (
      <>
        <div className={`App`}>
          <ThemeProvider>
            <TitleProvider>
              <Paper square={true} elevation={0}>
                <>
                  <CssBaseline />
                  <Component
                    config={this.state.config}
                    {...pageProps}
                    {...this.props}
                    checkingCache={this.state.checkingCache}
                  />
                </>
                <NoSsr>
                  <Snackbar ref={this.snackbarRef} />
                  {this.renderUnsubscribedPopup()}
                </NoSsr>
              </Paper>
            </TitleProvider>
          </ThemeProvider>
        </div>
      </>
    );
  }
}
