
import PRODUCT from "./enums/product.enum";
import {
    ROLES_MAPPING,
} from "src/constants";

// allowed users
let allowedMetricsUsers = ['metrics@colortokens.com', 'metricsdev@colortokens.com', 'metricsdemo@colortokens.com'];
let uapDomain = 'spectrum.colortokens.com';
let uapTestDomain = 'spectrum.colortokensecurecloud.com';
let userData;
let forceEnableMetrics = false;

import { METRIC_CATEGORY, METRIC_ACTION, METRIC_DETAIL } from "src/utils/metrics/enums/events.enum";

function canTrackUserMetrics(email) {
    return ((isProd()) || (email && (allowedMetricsUsers.indexOf(email) !== -1)) || forceEnableMetrics);
}

function setUserData(user) {

    let profile = { name: '', email: '' };
    if (user) {
        profile = {
            name: user?.displayName || user?.email || '',
            email: user?.email || ''
        }
    }

    if (profile) {

        userData = {
            profile: profile || { name: '', email: '' },
            tenant: user?.tenantName || '',
            user_id: user?.uid || '',
            role: ROLES_MAPPING[user?.role] || '',
            organization: user?.tenantName || ''
        }
    }

    return userData;
}

function getUserData() {
    return userData;
}

/**
 * Initialize Metrics
 *
 */
function init(user, url = '/login') {
    if (window?.mixpanel?.__loaded) {
        updateMetricsOptions(user, url);
    } else {
        setUserData(user);
        let userData = getUserData();
        if (userData) {
            registerMetricsUser(userData, url);
        }
        this.initialLoad = true;
    }
}

/**
 * Register Metrics
 *
 * @param {any} userData - user data
 */
function registerMetricsUser(userData, url = '/login') {
    if (userData?.profile && canTrackUserMetrics(userData?.profile?.email)) {
        const userid = userData?.user_id;
        const name = userData?.profile?.name;
        const email = userData?.profile?.email;
        const tenant = userData?.tenant;
        const role = userData?.role;
        const organization = userData?.organization;

        registerMixpanel(userid, name, email, tenant, role, organization, true);
        if (userid && userid !== '') {
            registerGoogleAnalytics(userid, url);
        }
    }
}

/**
* Update Metrics
*
*/
function updateMetricsOptions(user, url = '/login') {
    setUserData(user);

    let userData = getUserData();

    if (userData?.profile && canTrackUserMetrics(userData?.profile?.email)) {
        const userid = userData?.user_id;
        const name = userData?.profile?.name;
        const email = userData?.profile?.email;
        const tenant = userData?.tenant;
        const role = userData?.role;
        const organization = userData?.organization;

        updateMixpanelOptions(userid, name, email, tenant, role, organization);
        if (userid && userid !== '') {
            registerGoogleAnalytics(userid, url);
        }
    }
}

/**
* Deactivate Metrics
*/
function deActivateMetrics() {
    deActivateMixpanel();
}

/**
* update pendo settings
*/
function updateMixpanelOptions(userid, name, email, tenant, role, organization) {
    registerMixpanel(userid, name, email, tenant, role, organization);
}

/**
* Initialize mixpanel
*/
function initMixpanel() {
    window['mixpanel'].init(mixpanelAccountId);
}

/**
* Register Mixpanel
*
* @param {any} userData - user data
*/
function registerMixpanel(userid, name, email, tenant, role, organization, initialize = false) {

    if (window['mixpanel']) {
        try {
            if (initialize) {
                initMixpanel();
            }
            window['mixpanel'].register({
                // Unique identifier for current user
                name: name, // current user's name
                email: email, // Current user's email
                companyName: tenant, // Current user’s company
                role: role, // Current user’s role or permissions,
                organization: organization
            });
            window['mixpanel'].people.set({
                // Unique identifier for current user
                name: name, // current user's name
                email: email, // Current user's email
                $email: email,
                companyName: tenant, // Current user’s company
                role: role, // Current user’s role or permissions,
                organization: organization
            });

            window['mixpanel'].identify(userid);
        } catch (e) { }
    } else {
        console.log('mixpanel - not available');
    }

}

function deActivateMixpanel() {
    if (window['mixpanel']) {
        try {
            window['mixpanel'].reset();
        } catch (e) {
        }
    }
}

let gaAccountId = "";
// register GA session
function registerGoogleAnalytics(userid, pageUrl) {
    if (window['ga'] && gaAccountId) {
        window['ga']('create', gaAccountId, {
            userId: userid
        });
        window['gtag']('set', {
            user_id: userid
        });
        window['ga']('set', 'userId', userid);
        window['ga']('set', 'dimension1', userid);
        window['ga']('set', 'page', pageUrl);
        window['ga']('send', 'pageview');
    }
}

// record Analytics Event
function recordEvent(category, action, label, customData, value) {
    let userData = getUserData();
    if (userData) {
        let currentOrg = { displayName: userData?.tenant };
        let instance = { name: userData?.tenant };

        if (customData) {
            if (customData?.org) {
                currentOrg = customData?.org;
            }
            if (customData?.instance) {
                instance = customData?.instance;
            }
        }

        //  log GA events
        if (window['ga'] && gaAccountId) {
            window['ga']('send', {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventValue: value ? value : 1,
                hitCallback: function () {
                    // callback function
                }
            });
        } else {
            console.log('GA - not available');
        }

        //  log mixpanel events
        if (canTrackUserMetrics(userData?.profile?.email) && window['mixpanel'] && window['mixpanel'].track) {
            try {
                window['mixpanel'].track(category, {
                    hitType: 'event',
                    eventCategory: category,
                    product: PRODUCT.XCLOUD,
                    organization: currentOrg?.displayName || '',
                    instance: instance?.name || '',
                    eventAction: action,
                    eventLabel: label,
                    eventValue: value ? value : 1
                });
            } catch (e) {
            }
        } else {
            console.log('mixpanel - not available');
        }
    }
}

// router subscriber
function recordRouteChange(url = '/') {
    try {
        url = url.split('?')[0];

        const message = `${url} ${METRIC_ACTION.VISITED}`;

        window.securevia.metrics.recordEvent(METRIC_CATEGORY.PAGEVIEW, message, message);
        if (window['ga'] && gaAccountId) {
            window['ga']('set', 'page', url);
            window['ga']('send', 'pageview');
        } else {
            console.log('GA - not available in global object');
        }
    } catch (_) {
        return;
    }
}

function isSaasNode() {
    const url = window.location.href;
    if (url && ((url.indexOf(uapDomain) !== -1) || (url.indexOf(uapTestDomain) !== -1))) {
        return true;
    }
}

function isProd() {
    const url = window.location.href;
    if (url && (url.indexOf(uapDomain) !== -1)) {
        return true;
    }
    return false;
}

let Metrics = {
    init,
    updateMetricsOptions,
    deActivateMetrics,
    updateMixpanelOptions,
    registerMetricsUser,
    registerGoogleAnalytics,
    initMixpanel,
    registerMixpanel,
    deActivateMixpanel,
    recordEvent,
    recordRouteChange,
    isProd,
    canTrackUserMetrics
};

export default Metrics;