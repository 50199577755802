/*
 * ------------------------
 * File: /src/constants.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

const MODULES = {
  DEFINITIONS: "definitions",
  CVES: "cve",
  CONFIG: "config"
};

const SIGN_UP_TYPES = {
  INVALID: 0,
  FREE_SCAN: 1,
  PARTNER_SCAN: 2,
  PAID_SCAN: 3,
  FREE_CLOUD: 4,
  PARTNER_CLOUD: 5,
  PAID_CLOUD: 6
};

const PARTNER_USER_TYPES = [
  SIGN_UP_TYPES.PARTNER_SCAN,
  SIGN_UP_TYPES.PARTNER_CLOUD
];

const CLOUD_USER_TYPES = [
  SIGN_UP_TYPES.FREE_CLOUD,
  SIGN_UP_TYPES.PARTNER_CLOUD,
  SIGN_UP_TYPES.PAID_CLOUD
];

const FREE_USER_TYPES = [
  SIGN_UP_TYPES.FREE_SCAN,
  SIGN_UP_TYPES.PARTNER_SCAN,
  SIGN_UP_TYPES.FREE_CLOUD,
  SIGN_UP_TYPES.PARTNER_CLOUD
];

const PAID_USER_TYPES = [SIGN_UP_TYPES.PAID_SCAN, SIGN_UP_TYPES.PAID_CLOUD];

const DB_KEYS = {
  SIGN_UP_STATE: "signupState",
  USER_PROFILE_KEY: "userProfile"
};

const LOGIN_METHODS = {
  GOOGLE: "Google",
  TWITTER: "Twitter",
  GITHUB: "GitHub",
  FACEBOOK: "Facebook"
};

const ACTION_STATES = {
  PENDING: "PENDING",
  IN_PROCESS: "IN_PROCESS",
  FAILED: "FAILED",
  COMPLETE: "COMPLETE"
};

const ERROR_CODES = {
  INVALID_EMAIL: "auth/invalid-email",
  USER_DISABLED: "auth/user-disabled",
  USER_NOT_FOUND: "auth/user-not-found",
  WRONG_PASS: "auth/wrong-password",
  WEAK_PASSWORD: "auth/weak-password",
  DENIED: "auth/email-already-in-use"
};

const FILTER_OPTIONS = {
  PRODUCT_FAMILY: "Product Family List",
  CLASS: "Class List",
  PRODUCT: "Product List",
  PRODUCT_PLATFORM: "Product Platform List"
};

const CVE_FITER_OPTIONS = {
  VENDOR: "Vendor",
  PRODUCT: "Product",
  SEVERITY: "Severity",
  CATEGORY: "Categories",
  VERSION: "Versions",
  PROBLEM: "Problem"
};

const AGGREGATION_LEVELS = {
  0: "NONE",
  1: "PRODUCT_FAMILY",
  2: "PRODUCT_PLATFORM",
  3: "CLASS"
};

const CVE_AGGREGATION_LEVELS = {
  0: "NONE",
  1: "VENDOR",
  2: "PRODUCT",
  3: "VERSION"
};

const SCAP_FILE_TYPES = {
  XCCDF: "XCCDF",
  OVAL: "OVAL",
  COMBINED: "COMBINED",
  ARF: "ARF",
  OVAL_RESULTS: "OvalResults"
};
const FILTER_VALUES = {
  PRODUCT_FAMILY: "product_family",
  PRODUCT: "product",
  PRODUCT_PLATFORM: "product_platform",
  CLASS: "class",
  VENDOR: "vendor",
  SEVERITY: "severity",
  CATEGORY: "category",
  VERSION: "version",
  PROBLEM: "Problem",
  TEXT_SEARCH: "text_search",
  MONTH: "month"
};

const SEVERITY_LEVELS = {
  CRITICAL: "critical",
  HIGH: "high",
  HIGH_PLUS: "highplus",
  MEDIUM: "medium",
  MEDIUM_PLUS: "mediumplus",
  LOW: "low",
  LOW_PLUS: "lowplus",
  INFO: "info",
  INFO_PLUS: ""
};

const DEFINITION_SEVERITY = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "Low",
  CRITICAL: "critical"
};

const FILTER_NAME_MAP = {
  product_family: "Product Family",
  product_platform: "Product Platform",
  product: "Product",
  class: "Class",
  vendor: "Vendor",
  severity: "Severity",
  category: "Category",
  version: "Version",
  problem: "Problem",
  month: "Month"
};

const FILTER_VALUE_MAP = {
  "Product Family List": "product_family",
  "Product Platform List": "product_platform",
  "Product List": "product",
  "Class List": "class",
  "Vendor List": "vendor",
  "Severity List": "severity",
  "Category List": "category",
  "Version List": "version",
  "Problem List": "problem",
  "Month List": "month"
};

const EVENT_STREAMS = {
  DEFINITION_FITERS: "definitionFilters",
  CVE_FITERS: "cveFilters"
};

// Using Material Design color palettes from https://material.io/design/color/#tools-for-picking-colors
const CVE_COLORS = {
  NO_THREAT: "#3CD97D",
  INFO: "#BDBDBD",
  LOW: "#3B7AE0",
  MEDIUM: "#F4DB5E",
  HIGH: "#ED9B2C",
  CRITICAL: "#C94242"
};

const SEVERITY_LEVELS_MAP = {
  5: "Critical",
  4: "High",
  3: "Medium",
  2: "Low",
  1: "Info",
  undefined: "Unknown",
  CRITICAL: "Critical",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  INFO: "Info"
};

const CVE_SEVERITY_COLORS = {
  0: "#3CD97D",
  1: "#BDBDBD",
  2: "#3B7AE0",
  3: "#F4DB5E",
  4: "#ED9B2C",
  5: "#C94242",
  NONE: "#3CD97D",
  INFO: "#BDBDBD",
  LOW: "#3B7AE0",
  MEDIUM: "#F4DB5E",
  HIGH: "#ED9B2C",
  CRITICAL: "#C94242"
};

const RESOURCE_ICON_COLORS = {
  GREY: "#BDBDBD",
  ORANGE: "#ED9B2C",
  GREEN: "#3CD97D",
  RED: "#C94242",
  BLUE: "#3B7AE0"
};

const COMPLIANCE_COLORS = {
  FAIL: "#C94242",
  ERROR: "#ED9B2C",
  PASS: "#3CD97D"
};

const ACCOUNT_ACTIONS = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword"
};

const EMAIL_VERIFICATION_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  EMAIL_VERIFIED: "complete"
};

const LOCAL_STORAGE_KEYS = {
  VERIFICATION_PENDING: "verificationPending",
  LAST_USER: "lastUser",
  TENANT_ID: "tenantId",
  USER_PROFILE: "userProfile",
  SELECTED_THEME: "selectedTheme",
  AUTH_STATE: "authState"
};

const ROLES = {
  ADMIN: 1,
  VIEWER: 2,
  OPERATOR: 3
};

const ROLES_MAPPING = {
  1: "admin",
  2: "viewer",
  3: "operator"
};

const COLLECTIONS = {
  USER_DATA: "users",
  SHOWINGS: "showings",
  BOOKINGS: "bookings",
  LEADS: "leads"
};
const RESET_PASSWORD_STATES = {
  LOADING: "loading",
  INVALID_CODE: "invalid",
  VALID_CODE: "valid",
  RESET_COMPLETE: "complete"
};

const SUNBURST_ANIMATION_DURATION = 650;

const TEXTS = {
  DESCRIPTION:
    "ColorTokens is a full featured compliance and vulnerability scanner that makes it easy to enforce industry standard security configurations across your infrastructure in minutes. Automate your compliance and vulnerability scans and increase your visibility of insecure systems. Harden your infrastructure by patching and configuring your systems with industry standard security configurations. Audit all your systems and meet government & industry regulations.",
  TITLE: "ColorTokens - Information Security & Compliance",
  COMMON_KEYWORDS:
    "CVE, Oval, CPE, NIST, NVD, Vulnerability, Compliance Checks, Hardening, SCAP, DISA, STIG, Assessment, Audit",
  CVE_COMMON_KEYWORDS: "CVE, CPE, NIST, NVD, Vulnerability, Compliance Checks",
  OVAL_COMMON_KEYWORDS:
    "Oval, XCCDF, Compliance Checks, Hardening, SCAP, DISA, STIG, Assessment, Audit"
};

const DEFINITION_MODES = {
  COMPLIANCE_BENCHMARK: "benchmark",
  VISUAL_ANALYTICS: "analytics"
};

const SCANNER_MODES = {
  INVALID: 0,
  LOCAL: 1,
  REMOTE: 2,
  NON_OS: 3,
  APPS: 4
};

const TENANT_TIER_TYPES = {
  NONETIER: 0,
  FREESCAN: 1,
  FREECLOUD: 2,
  PAIDCLOUD: 3
};

const BENCHMARK_TYPES = {
  ALL: "allbenchmarks",
  LOCAL_OS: "localosbenchmarks",
  ALL_OS: "allosbenchmarks",
  DB: "dbbenchmarks",
  OTHERS: "otherbenchmarks"
};

const DEFAULT_SCAN_CONFIG = {
  ConfigPath: "",
  Apikey: "",
  Secret: "",
  Install: false,
  Compliance: true,
  OvalFile: "",
  TailoringFile: "",
  VariableFile: "",
  DirectivesFile: "",
  DictionaryFile: "",
  EvaluateType: "xccdf",
  ProbeType: "",
  ExportVariables: false,
  Benchmark: "",
  ProfileName: "",
  RuleID: "",
  Definition: "",
  Vulnerability: true,
  ScanType: null,
  SoftwareScan: false,
  LibraryScan: false,
  ProcessScan: false,
  NetworkScan: false,
  ScanDir: null,
  ProcessRegexp: null,
  CombinedReport: "",
  ReportTitle: "",
  ResultFile: "",
  ResultDir: "",
  RemoteConfig: null,
  RemoteConfigFile: "",
  KeyFileDir: "",
  CLI: false,
  GUI: true,
  Silent: true,
  NoProgressBar: false,
  NoBanner: false,
  NoShowReport: true,
  Verbose: false,
  Interactive: false,
  Version: false,
  Help: false,
  LogDir: "",
  LogFile: "",
  LogLevel: ""
};

const SCANNER_STEPPER_LOCAL = {
  COMPLIANCE: 0,
  VULNERABILITY: 1,
  APPLICATION_ASSESSMENT: 2
};

const SCANNER_STEPPER_REMOTE = {
  REMOTE_SERVER: 0,
  COMPLIANCE: 1,
  VULNERABILITY: 2,
  APPLICATION_ASSESSMENT: 3
};

const MESSAGES = {
  COMPLIANCE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  VULNERABILITY:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  APPLICATION_ASSESSMENT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  REMOTE:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
};

const ACCOUNT_TYPES = {
  CLOUD: "Cloud",
  UNO: "Uno"
};

const USER_STATUS = {
  INVALID: 0,
  PENDING: 1,
  REGISTERED: 2,
  BLOCKED: 3
};

const PROVIDERS = {
  AWS: 2
};

const PROVIDER_NAMES = {
  AWS: "aws",
  WEBSITE: "website",
  WEB: "web",
  DIRECT: "direct",
  INVITE: "invite"
};

const AUTHORING_TEST_TYPES = {
  OS_QUERY: "os_query",
  OS_COMMAND: "os_command",
  SQL_TEST: "sql_test",
  FILE_TEST: "file_test",
  TEXT_CONTENT: "text_content"
};

const AUTHORING_TEST_STATE_OP = {
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  GREATER_OR_EQUAL: "ge",
  LESS_OR_EQUAL: "le",
  EQUAL: "eq"
};

const TEXTFILECONTENT_OP = {
  MATCH: "pattern match",
  NOT_MATCH: "not equal"
};

const EMAIL_ACTIONS = {
  VERIFY_EMAIL: "verifyEmail"
};

const DISABLED_FEATURES = {
  CLOUD: "CLOUD",
  AWS: "AWS",
  AUTHORING: "AUTHORING",
  COMMUNITY: "COMMUNITY",
  CLOUD_SCAN: "CLOUD-SCAN",
  SELF_SIGNUP: "SELF-SIGNUP"
};

const RESOURCE_FILTER_PILL_TYPES = {
  SEARCH: "Search",
  HAS_MUST_FIX: "Has Must Fix",
  HAS_HIGH_FAILURES: "Has High Failures",
  TYPE: "Type",
  SECURE: "Is Secure",
  MALWARE: "Has Malware",
  HAS_NICE_TO_FIX: "Has Nice To Fix",
  SCANNED_RESOURCES: "Scanned Resources",
  SECURITY_PATCHES: "Has Security Patches",
  FILTER: "Filter",
  NAME_SEARCH: "Name",
  CVE_KEYWORD: "CVE Keyword",
  CVE_ID: "CVE ID",
  CVE_DATE_SEARCH: "CVE Date Search",
  CPE_KEYWORD: "CPE Keyword",
  CPE_NAME: "CPE Name",
  TREEMAP_ONE: "treemapOne",
  TREEMAP_TWO: "treemapTwo",
  TAG_SEARCH: "Tag Key"
};

const NG_ROUTES_MAP = {
};


const SOCIAL_LINKS = {
  TWITTER: "https://twitter.com/colortokensinc",
  LINKEDIN: "https://in.linkedin.com/company/colortokens-inc."
};

const ANALYTIC_EVENT_TYPES = {
  APP: "App",
  USER: "User"
};

const ANALYTIC_EVENTS = {
  LOGIN_CLICK: "login_click",
  SIGNUP_CLICK: "signup_click",
  SOCIAL_SIGNUP_CLICK: "social_signup_click",
  DOWNLOAD_CLICK: "download_click",
  COPY_INSTRUCTIONS_CLICK: "copy_instructions_click",
  AWS_CLICK: "aws_link_click"
};

const STATS_MAP = {
  1: "Resources discovered",
  2: "Eligible",
  3: "Started",
  4: "Success",
  5: "Failed",
  6: "Skipped"
};

const STATS_REVERSE_MAP = {
  "Resources discovered": 1,
  Eligible: 2,
  Started: 3,
  Success: 4,
  Failed: 5,
  Skipped: 6
};

const COMPLIANCE_ALL_ACCOUNTS_FILTER = {
  ALL: {
    label: "All Accounts",
    value: "all"
  },
  AWS: {
    label: "All AWS Accounts",
    value: "AWS"
  },
  Azure: {
    label: "All Azure Accounts",
    value: "Azure"
  },
  GCP: {
    label: "All GCP Accounts",
    value: "GCP"
  }
};

const COMPLIANCE_ALL_AWS_ACCOUNTS_FILTER = {
  label: "All AWS Accounts",
  value: "AWS"
};

const COMPLIANCE_RULE_REQUIREMENT_RESULTS = {
  PASS: "pass",
  FAIL: "fail"
};

const THEME_TYPES = {
  LIGHT: "light",
  DARK: "dark"
};

const SUPPORT_EMAIL = "support@securevia.com";

const ADDRESS = "Boston, MA, USA";

const AWS_ONBOARDING_DEMO_VIDEO = "https://vimeo.com/639884110";
const AZURE_ONBOARDING_DEMO_VIDEO = "https://vimeo.com/639884110";
const GCP_ONBOARDING_DEMO_VIDEO = "https://vimeo.com/639884110";

const CVE_SEARCH_MODES = {
  NAME: "Keyword",
  ID: "ID"
};

const AUTH_STATES= {
  Unknown: 0,
  LoggedIn: 1,
  LoggedOut: 2,  
}


module.exports = {
  DB_KEYS: DB_KEYS,
  ACTION_STATES: ACTION_STATES,
  ERROR_CODES: ERROR_CODES,
  LOGIN_METHODS: LOGIN_METHODS,
  FILTER_OPTIONS: FILTER_OPTIONS,
  AGGREGATION_LEVELS: AGGREGATION_LEVELS,
  FILTER_VALUES: FILTER_VALUES,
  FILTER_NAME_MAP,
  FILTER_VALUE_MAP,
  CVE_FITER_OPTIONS,
  SUNBURST_ANIMATION_DURATION,
  EVENT_STREAMS,
  WORD_CLOUD_COUNT: 250,
  MODULES,
  CVE_AGGREGATION_LEVELS,
  CVE_COLORS,
  SEVERITY_LEVELS,
  DEFINITION_SEVERITY,
  CVE_SEVERITY_COLORS,
  SCAP_FILE_TYPES,
  SEVERITY_LEVELS_MAP,
  ACCOUNT_ACTIONS,
  EMAIL_VERIFICATION_STATES: EMAIL_VERIFICATION_STATES,
  LOCAL_STORAGE_KEYS: LOCAL_STORAGE_KEYS,
  COLLECTIONS,
  ROLES,
  ROLES_MAPPING,
  RESET_PASSWORD_STATES,
  TEXT: TEXTS,
  DEFINITION_MODES,
  STEPPER: SCANNER_STEPPER_LOCAL,
  DEFAULT_CONFIG: DEFAULT_SCAN_CONFIG,
  SCANNER_MODES,
  MESSAGES,
  SCANNER_STEPPER_REMOTE,
  BENCHMARK_TYPES,
  ACCOUNT_TYPES,
  USER_STATUS,
  AUTHORING_TEST_TYPES,
  AUTHORING_TEST_STATE_OP,
  TEXTFILECONTENT_OP,
  EMAIL_ACTIONS,
  DISABLED_FEATURES,
  TENANT_TIER_TYPES,
  PROVIDERS,
  PROVIDER_NAMES,
  SOCIAL_LINKS,
  NG_ROUTES_MAP,
  ANALYTIC_EVENTS,
  ANALYTIC_EVENT_TYPES,
  ADDRESS,
  SUPPORT_EMAIL,
  SIGN_UP_TYPES,
  FREE_USER_TYPES,
  CLOUD_USER_TYPES,
  COMPLIANCE_COLORS,
  PARTNER_USER_TYPES,
  PAID_USER_TYPES,
  RESOURCE_ICON_COLORS,
  AWS_ONBOARDING_DEMO_VIDEO,
  AZURE_ONBOARDING_DEMO_VIDEO,
  GCP_ONBOARDING_DEMO_VIDEO,
  STATS_MAP,
  STATS_REVERSE_MAP,
  THEME_TYPES,
  COMPLIANCE_ALL_ACCOUNTS_FILTER,
  COMPLIANCE_RULE_REQUIREMENT_RESULTS,
  RESOURCE_FILTER_PILL_TYPES,
  CVE_SEARCH_MODES,
  AUTH_STATES
};