import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ActionButton = ({ url, isNewTab }) => {
  const onClick = () => {
    if (isNewTab) {
      const win = window.open(url, "_blank");
      win.focus();
    } else {
      window.location = url;
    }
  };

  return (
    <Button color="secondary" size="small" onClick={onClick}>
      View
    </Button>
  );
};

const styles = theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    alignItems: "center"
  }
});

class SnackbarWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  showSuccess = (message, extras) => {
    this.setState({
      open: true,
      severity: "success",
      message,
      extras
    });
  };

  showError = (message, extras) => {
    this.setState({
      open: true,
      severity: "error",
      message,
      extras
    });
  };

  showWarning = (message, extras) => {
    this.setState({
      open: true,
      severity: "warning",
      message,
      extras
    });
  };

  showInfo = (message, extras) => {
    this.setState({
      open: true,
      severity: "info",
      message,
      extras
    });
  };

  handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let autoHideDuration = 2000;
    if (this.state.extras && this.state.extras.duration) {
      autoHideDuration = this.state.extras.duration;
    }

    return (
      <div className={classes.root}>
        <Snackbar
          open={this.state.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={autoHideDuration}
          onClose={this.handleClose}
        >
          <Alert
            className="notificationSnackbar"
            onClose={this.handleClose}
            severity={this.state.severity}
            action={
              this.state?.extras?.url ? (
                <ActionButton
                  url={this.state?.extras?.url}
                  isNewTab={this.state?.extras?.isNewTab}
                />
              ) : null
            }
          >
            {"" + this.state.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(SnackbarWrapper);
