// var ReactGA = require('react-ga');
// setupGoogleAnalytics();

// async function setupGoogleAnalytics() {
//     let config = await APIHandler.getConfig();
//     if (config && config.FIREBASE_MEASUREMENT_ID) {
//         debugger
//         ReactGA.initialize(config.FIREBASE_MEASUREMENT_ID, { debug: true });
//     }
// }

async function logEvent(event, data, eventType) {
  window.gtag && window.gtag("event", "engagement", {
    event_category: event,
    event_label: event,
    event_callback: function () {
      console.log("Sent event to ga");
    }
  });
  // window.securevia.database.logFirebaseEvent(event, data)
}

let analytics = {
  logEvent
};
export default analytics;
