/*
 * ------------------------
 * File: /src/utils/networking/HttpHelper.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */

/*
usage
import {sendRequest} from '../Base/HttpHelper.js'
*/

import fetch from "isomorphic-unfetch";
import { MODULES } from "src/constants";

import { from } from "rxjs";
import { shareReplay } from "rxjs/operators";
import getConfig from "next/config";
const { serverRuntimeConfig } = getConfig();

let SERVER_ADDRESS = serverRuntimeConfig.SERVER_URL;
// let SERVER_ADDRESS;
const CONTENT_TYPE_HEADER = "Content-Type";
const CONTENT_TYPE_JSON = "application/json";
const CONTENT_TYPE_JSON_WITH_ENCODING = "application/json; charset=utf-8";

const defaultHeaders = {};

const INVALID_BODY_MESSAGE = "Please check the information you provided";
const FORBIDDEN = "Action Denied";
const SERVICE_ERROR =
  "Something went wrong on our side, Our support team is looking into it";

const AUTHORIZATION_HEADER = "Authorization";

const defaultErrorMessagesMap = {
  400: INVALID_BODY_MESSAGE,
  403: FORBIDDEN,
  500: SERVICE_ERROR
};

const pipeStatus = (config, response) => {
  let errorMessagesMap = config.errorMessagesMap;

  let messaesMap = defaultErrorMessagesMap;

  if (errorMessagesMap) {
    messaesMap = Object.assign({}, defaultErrorMessagesMap, errorMessagesMap);
  }

  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    let message = response.statusText;
    let errorMessage = messaesMap[response.status] || message;

    return new Promise(function(resolve, reject) {
      response
        .text()
        .then(message => {
          if (message === response.statusText) {
            throw new Error("Server didnt send custom messages, use ours");
          }
          reject({
            message: message || errorMessage,
            status: response.status
          });
        })
        .catch(() => {
          reject({
            message: errorMessage,
            status: response.status
          });
        });
    });
  }
};

const pipeData = (options, response) => {
  if (options.download) {
    return response.blob();
  }
  let dataType = response.headers.get(CONTENT_TYPE_HEADER);
  dataType = dataType || "";
  switch (dataType.toLowerCase()) {
    case CONTENT_TYPE_JSON:
    case CONTENT_TYPE_JSON_WITH_ENCODING:
      return response.json();
    default:
      return response.text();
  }
};

const _getURLWithQuery = (url, params) => {
  if (!params) {
    return url;
  }
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .filter(key => {
      return params[key] !== "";
    })
    .map(k => esc(k) + "=" + esc(params[k]))
    .join("&");

  return url + (query ? "?" + query : "");
};

const connectionError = {
  message: "Could not connect to SecureVia servers"
};

let refreshedToken = false;

const sendRequest = async (api, method, body, callback, config) => {
  const originalBody = body;
  if (!config) {
    config = {};
  }

  if (!config.directHit && !SERVER_ADDRESS) {
    try {
      let appConfig = await loadEnvConfig();
      SERVER_ADDRESS = appConfig.SERVER_URL;
      console.log("Loaded config", appConfig);
    } catch (e) {
      throw e;
    }
  }

  let url = config.directHit ? api : SERVER_ADDRESS + api;
  if (!method) {
    throw new Error("Http method needs to be defined");
  }

  let headersToSend = Object.assign({}, defaultHeaders);
  if (config.headers) {
    headersToSend = Object.assign({}, defaultHeaders, config.headers);
  } else {
    switch (method.toLowerCase()) {
      case "post":
      case "put":
      case "patch":
        headersToSend[CONTENT_TYPE_HEADER] = CONTENT_TYPE_JSON;
        break;
      default:
        break;
    }
  }
  if (config.contentType) {
    headersToSend[CONTENT_TYPE_HEADER] = config.contentType;
  }
  let cachePolicy = config.cachePolicy || "no-cache";

  let cancelSignal;
  let abortController;
  if (typeof window !== "undefined" && window.AbortController) {
    abortController = new window.AbortController();
    cancelSignal = abortController.signal;
  }

  var options = {
    method: method,
    headers: headersToSend,
    cache: cachePolicy,
    signal: cancelSignal
  };

  if (config.useQueryParams) {
    url = _getURLWithQuery(url, body);
  } else if (!config.useQueryParams && config.useAsBody) {
    headersToSend[CONTENT_TYPE_HEADER] = CONTENT_TYPE_JSON;
    body =
      headersToSend[CONTENT_TYPE_HEADER] !== CONTENT_TYPE_JSON
        ? body
        : JSON.stringify(body);
    options.body = body;
  } else {
    switch (method.toLowerCase()) {
      case "post":
      case "put":
      case "patch":
        body =
          headersToSend[CONTENT_TYPE_HEADER] !== CONTENT_TYPE_JSON
            ? body
            : JSON.stringify(body);
        options.body = body;
        break;
      case "get":
      case "delete":
        url = _getURLWithQuery(url, body);
        break;
      default:
        throw new Error("Unsupported method");
    }
  }

  if (!headersToSend[CONTENT_TYPE_HEADER]) {
    delete headersToSend[CONTENT_TYPE_HEADER];
  }

  options.method = options.method.toUpperCase();

  if(config.requiresAuth) {
    options.credentials= 'include'
  }
  const process = () => {
    fetch(url, options)
      .then(pipeStatus.bind(this, config))
      .then(pipeData.bind(this, config.responseOptions || {}))
      .then(response => {
        // Separate out stack from promise stack
        setTimeout(callback.bind(this, response), 10);
      })
      .catch(error => {
        if (error.code === 20 || error.name === "AbortError") {
          return;
        }

        console.warn("HTTP error", error);

        if (error.status === 401 && !api.includes("user")) {
          refreshedToken = false;
          if(typeof window !== undefined && window?.securevia?.updateCookie) {
            window.securevia.updateCookie();
          }
          setTimeout(() => {
            sendRequest(api, method, originalBody, callback, config);
          }, 1500)
          return;
        }

        if (config.errorCallback) {
          config.errorCallback(error);
        }

        if (config.dontShowMessage) {
          return;
        }

        if (error.status && error.status !== 401 && error?.message?.includes("Message")) {
          let message = JSON.parse(error.message);
          typeof window !== "undefined" &&
            window.NotificationUtils.showError(message.Message, {
              duration: 6000
            });
        }
      });
  };
  process();

  return abortController;
};

let CurrentActiveRequests = {};
async function loadEnvConfig() {
  if (typeof window === "undefined") {
    return Promise.resolve({});
  }

  CurrentActiveRequests[MODULES.CONFIG] =
    CurrentActiveRequests[MODULES.CONFIG] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CONFIG];
  let source;

  if (currentActiveRequests && currentActiveRequests.length) {
    source = currentActiveRequests[0];
  } else {
    source = from(
      new Promise((resolve, reject) => {
        sendRequest(
          window.location.origin + "/api/_config",
          "get",
          {},
          resolve,
          {
            requiresAuth: false,
            errorCallback: reject,
            directHit: true
          }
        );
      })
    );
    source = source.pipe(shareReplay(1));
    currentActiveRequests[0] = source;
  }

  return new Promise((resolve, reject) => {
    source.subscribe(results => {
      resolve(results);
    }, reject);
  });
}

let helperApis = {
  /*
  Study the config for advanced usage
  It has headers map, errorMessages map, dontShowMessage: boolean options
  To remove Content-Type header, set it to false
  To make user logout on access denied use logoutOnDeny: true
  */
  sendRequest: sendRequest,

  pipeStatus: pipeStatus,

  pipeData: pipeData,

  connectionError: connectionError,

  loadEnvConfig
};

module.exports = helperApis;
