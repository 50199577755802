import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { THEME_TYPES, LOCAL_STORAGE_KEYS } from "src/constants";

const LIGHT_THEME = createTheme(require("../../theme.json"));
const DARK_THEME = createTheme(require("../../darkTheme.json"));

export const ThemeContext = React.createContext();

class CustomThemeProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: THEME_TYPES.LIGHT,
      theme: LIGHT_THEME
    };
  }

  componentDidMount() {
    const storedTheme =
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_THEME) ||
      THEME_TYPES.LIGHT;
    if (storedTheme !== this.state.type) {
      this.toggleTheme();
    }
  }

  toggleTheme = () => {
    let newType = THEME_TYPES.LIGHT;
    let newTheme = LIGHT_THEME;
    if (this.state.type === THEME_TYPES.LIGHT) {
      newType = THEME_TYPES.DARK;
      newTheme = DARK_THEME;
    }
    this.setState({
      type: newType,
      theme: newTheme
    });
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_THEME, newType);
    document.documentElement.setAttribute("data-theme", newType);
  };

  render() {
    const { type, theme } = this.state;
    const { toggleTheme } = this;
    return (
      <ThemeContext.Provider
        value={{
          type,
          toggleTheme
        }}
      >
        <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
      </ThemeContext.Provider>
    );
  }
}

export default CustomThemeProvider;
