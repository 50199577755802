import React, { Component } from "react";

export const TitleContext = React.createContext();

class TitleProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  setTitle = title => {
    this.setState({
      title
    });
  };

  render() {
    const { title } = this.state;
    const { setTitle } = this;
    return (
      <TitleContext.Provider
        value={{
          title,
          setTitle
        }}
      >
        {this.props.children}
      </TitleContext.Provider>
    );
  }
}

export default TitleProvider;
