/*
 * ------------------------
 * File: /src/utils/networking/APIHandler.js
 * Project: scap-viewer
 * ------------------------
 * Copyright colortokens.com - All Rights Reserved.
 * The intellectual and technical concepts contained herein are proprietary to colortokens.com
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * ------------------------
 */
import moment from "moment";
import { sendRequest, loadEnvConfig } from "./HttpHelper";
const axios = require("axios");
import {axiosInstance} from  "./axios";

import {
  WORD_CLOUD_COUNT,
  MODULES,
  EMAIL_ACTIONS
} from "src/constants";
const DEFINITIONS_FILTERS_SELECT = { type: "facet", list: "all" };
const CVE_FILTERS_SELECT = { type: "facet", list: "all" };
const CVE_CATEGORIES_SELECT = { type: "hierarchy", list: "category" };
const CVE_VERSIONS_SELECT = { type: "hierarchy", list: "version" };
const CVE_PROBLEMS_SELECT = { type: "hierarchy", list: "problem" };
const CVE_VP_SUNBURST_SELECT = { type: "hierarchy", list: "VP" };
const CVE_PV_SUNBURST_SELECT = { type: "hierarchy", list: "PV" };
const CVE_STACKED_BAR = { type: "stackedBar" };
const DEFINITIONS_FIRST_LEVEL_SUNBURST = { type: "hierarchy", list: "FP" };
const DEFINITIONS_SECOND_LEVEL_SUNBURST = { type: "hierarchy", list: "PC" };
const WORDCLOUD_SELECT = { type: "hierarchy", list: "product" };

const CVE_SUNBURST_LIMIT = 20;
const CVE_TAG_CLOUD_LIMIT = 250;

const CurrentActiveRequests = {};

function cancelCurrentActiveRequests(key) {
  let currentActiveRequests = CurrentActiveRequests[key] || [];

  let length = currentActiveRequests.length;
  for (let i = 0; i < length; i++) {
    let req = currentActiveRequests.shift();
    try {
      req && req.abort && req.abort();
    } catch (e) {
      console.error(e);
    }
  }
}

function createAccount(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/signup", "post", params, resolve, {
      requiresAuth: false,
      errorCallback: reject
    });
  });
}

function getInviteDetails() {
  let invite = {
    email: "test@gmail.com",
    role: "Admin"
  };
  return new Promise(resolve => {
    setTimeout(resolve.bind(this, invite), 1500);
  });
}

function getTenantDetails() {
  return new Promise((resolve, reject) => {
    sendRequest("/user/tenant", "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function prepareQueryParams(filters) {
  let body = {};
  body["filter"] = filters;
  return body;
}

function getDefinitions(params) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/definitions/list", "get", params, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getDefinitionDetails(id) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest(`/definition/${id}`, "get", {}, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getDefinitionsFilters(params, select = DEFINITIONS_FILTERS_SELECT) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select,
    ...params
  });

  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/definitions/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getDefinitionsSunburstFirstLevel(params) {
  return getSunburstData(params, DEFINITIONS_FIRST_LEVEL_SUNBURST);
}

function getDefinitionsSunburstSecondLevel(params) {
  return getSunburstData(params, DEFINITIONS_SECOND_LEVEL_SUNBURST);
}

function getSunburstData(params, select = DEFINITIONS_FIRST_LEVEL_SUNBURST) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: select,
    ...params
  });

  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/definitions/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getWordCloud(params) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: WORDCLOUD_SELECT,
    ...params,
    limit: WORD_CLOUD_COUNT
  });

  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/definitions/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function createCredentials(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/credential", "post", params || {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getCredentials() {
  return new Promise((resolve, reject) => {
    sendRequest("/credential/list", "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject,
      logoutOnDeny: true
    });
  });
}

function revokeCredentials(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/credential", "delete", params || {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function updateCredentialsLabel(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/credential", "put", params || {}, resolve, {
      requiresAuth: true,
      errorCallback: reject,
      useQueryParams: true
    });
  });
}

function getCves(params) {
  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/list", "get", params || {}, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveDetails(params) {
  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest(`/cve/${params.id}`, "get", {}, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveFilters(params, select = CVE_FILTERS_SELECT) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveCategory(params) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: CVE_CATEGORIES_SELECT,
    limit: CVE_TAG_CLOUD_LIMIT,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveVersion(params) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: CVE_VERSIONS_SELECT,
    limit: CVE_TAG_CLOUD_LIMIT,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveProblem(params) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: CVE_PROBLEMS_SELECT,
    limit: CVE_TAG_CLOUD_LIMIT,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveSunburstVPData(params) {
  return getCveSunburstData(params, CVE_VP_SUNBURST_SELECT);
}

function getCveSunburstPVData(params) {
  return getCveSunburstData(params, CVE_PV_SUNBURST_SELECT);
}

function getCveSunburstData(params, select, limit) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: select,
    limit: limit || CVE_SUNBURST_LIMIT,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function getCveStackedBarData(params) {
  params = prepareQueryParams(params);
  let query = JSON.stringify({
    select: CVE_STACKED_BAR,
    ...params
  });

  CurrentActiveRequests[MODULES.CVES] =
    CurrentActiveRequests[MODULES.CVES] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.CVES];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/cves/analytics", "get", { query }, resolve, {
        errorCallback: reject
      })
    );
  });
}

function parseInteractiveReport(params) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/report/upload", "post", params.file, resolve, {
        errorCallback: reject,
        headers: {
          "Content-Type": false
        }
      })
    );
  });
}

function downloadOVALReport(params) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/report/download/oval", "post", params, resolve, {
        errorCallback: reject
      })
    );
  });
}

function downloadXCCDFReport(params) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/report/download/xccdf", "post", params, resolve, {
        errorCallback: reject
      })
    );
  });
}

function createOrganization(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/tenant", "post", params, resolve, {
      errorCallback: reject,
      dontShowMessage: true
    });
  });
}

function updateOrganization(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/tenant", "put", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getOrganization() {
  return new Promise((resolve, reject) => {
    sendRequest("/user/tenant", "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getFreeTenantId() {
  return new Promise((resolve, reject) => {
    sendRequest("/tenant/free-tenant-id", "get", null, resolve, {
      errorCallback: reject
    });
  });
}

function getCredentialDetails(apikey) {
  return new Promise((resolve, reject) => {
    sendRequest(`/credential/${apikey}`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getComplianceBenchmarks() {
  return new Promise((resolve, reject) => {
    sendRequest("/content", "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getBenchmarks(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/benchmark/list", "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getBenchmark(bid) {
  return new Promise((resolve, reject) => {
    sendRequest(`/benchmark/${bid}`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function addBenchmark(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/benchmark", "post", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function updateBenchmark(id, params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/benchmark/${id}`, "put", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function deleteBenchmark(id) {
  return new Promise((resolve, reject) => {
    sendRequest(`/benchmark/${id}`, "delete", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getBenchmarkProfiles(component_platform, component_id) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/content/profiles",
      "get",
      { id: component_platform, component: component_id },
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getChecksOfProfile(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/content/profile/checks", "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function addCheckToProfile(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/content/profile/check", "post", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function deleteCheckFromProfile(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/content/profile/check`, "delete", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

let config;
async function getConfig() {
  if (config) {
    return Promise.resolve(config);
  }
  config = await loadEnvConfig();
  return config;
}

function forgotTenant(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/tenant/forgot-tenant-id", "get", params, resolve, {
      errorCallback: reject
    });
  });
}

function getCompartments() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/compartment/hierarchy",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getConnectedAccounts() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/cloudscanner/summary",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getCriticalAlerts() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/asset/criticalAlerts",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getResourcesSummary() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/asset/summary",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getSeveritySummary() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/asset/summaryLevels",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getRiskSummary(query) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/asset/summaryByType",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getAssets(query) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/asset/search",
      "get",
      { query },
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function addCompartment(data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/compartment",
      "post",
      data,
      () => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function moveCompartment(name, data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/compartment/${name}/move`,
      "patch",
      data,
      () => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function deleteCompartment(name) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/compartment/${name}`,
      "delete",
      null,
      () => {
        resolve();
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function updateCompartment(name, data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/compartment/${name}`,
      "patch",
      data,
      res => {
        resolve(res);
      },
      {
        requiresAuth: true,
        errorCallback: reject,
        dontShowMessage: true
      }
    );
  });
}

function getUserAccount() {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/user",
      "get",
      { details: true },
      profile => {
        resolve(profile);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function inviteUsers(data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/user/invite",
      "post",
      data,
      response => {
        resolve(response);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getUsers() {
  return new Promise((resolve, reject) => {
    return sendRequest(
      "/user/list",
      "get",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function updateUserPrivileges(userID, change) {
  return new Promise((resolve, reject) => {
    return sendRequest(
      `/user/${userID}/privileges`,
      "patch",
      change,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function deleteUser(userID) {
  return new Promise((resolve, reject) => {
    return sendRequest(
      `/user/${userID}`,
      "delete",
      null,
      data => {
        resolve(data);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getTenantName(data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/tenant/tenant-id-from-name",
      "get",
      data,
      tenantID => {
        resolve(tenantID);
      },
      {
        requiresAuth: false,
        errorCallback: reject
      }
    );
  });
}

function validateInvite(data) {
  return new Promise((resolve, reject) => {
    sendRequest(
      "/invite",
      "get",
      data,
      () => {
        resolve();
      },
      {
        requiresAuth: false,
        errorCallback: reject,
        dontShowMessage: true
      }
    );
  });
}

function updateUserCompartments(user, compartments) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/user/${user.uid}/compartments`,
      "patch",
      { compartments },
      () => {
        resolve();
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getChecks(params) {
  CurrentActiveRequests[MODULES.DEFINITIONS] =
    CurrentActiveRequests[MODULES.DEFINITIONS] || [];
  let currentActiveRequests = CurrentActiveRequests[MODULES.DEFINITIONS];

  return new Promise((resolve, reject) => {
    currentActiveRequests.push(
      sendRequest("/check/list", "get", params, resolve, {
        requiresAuth: true,
        errorCallback: reject
      })
    );
  });
}

function addCheck(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/check", "post", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function updateCheck(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/check/${params.cid}`, "put", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function deleteCheck(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/check/${params.cid}`, "delete", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function verifyEmail(params) {
  params.mode = EMAIL_ACTIONS.VERIFY_EMAIL;
  return new Promise((resolve, reject) => {
    sendRequest(`/signup/account-actions`, "post", params, resolve, {
      requiresAuth: false,
      errorCallback: reject
    });
  });
}

function forgotPassword(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/signup/reset-password", "post", params, resolve, {
      requiresAuth: false,
      errorCallback: reject
    });
  });
}

function sendEmailVerification(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/signup/resend-email-verify`, "post", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getReportURL(params) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/asset/${params.aid}/assessmentReportURL/${params.reportObjectID}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getAssessments(assetId, offset, limit) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/asset/${assetId}/assessments`,
      "get",
      { offset: offset, limit: limit },
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getAssetDetails(assetId) {
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/${assetId}/details`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getAssetChartData(assetId, startTime, endTime) {
  let params = {};
  params.startTime = startTime || 1621195290639;
  params.endTime = endTime || moment().unix();
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/${assetId}/chartdata`, "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function moveAssetsToCompartment(assets, compartment) {
  let params = {
    assetIDs: assets,
    compartment: compartment
  };
  return new Promise((resolve, reject) => {
    sendRequest(`/assets/compartment`, "patch", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function deleteAssets(assets) {
  let params = {
    assetIDs: assets
  };
  return new Promise((resolve, reject) => {
    sendRequest(`/assets`, "delete", params, resolve, {
      requiresAuth: true,
      errorCallback: reject,
      useAsBody: true
    });
  });
}

function getAWSSetupURL() {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/aws/setupURL`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getUpdateURL(cloudType, accountID) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/cloudscanner/${cloudType}/updateURL/${accountID}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function setUpdatedStack(accountID, params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/${accountID}`, "patch", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getAzureSetupURL() {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/azure/setupURL`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getGcpSetupURL() {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/gcp/setupURL`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getGcpStatus(params) {
  let query = {...params};
  return new Promise((resolve, reject) => {
    sendRequest(
      `/cloudscanner/gcp/confirm`,
      "get",
      query,
      response => {
        resolve(response);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getAzureResourceTemplate(tenantId, subscriptionId) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/azure/resourcetemplate/${tenantId}/${subscriptionId}`,
      "get",
      {},
      response => {
        resolve(response);
      },
      {
        requiresAuth: false,
        errorCallback: reject
      }
    );
  });
}

function getAzureStatus(query) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/cloudscanner/connectxcloud`,
      "get",
      query,
      response => {
        resolve(response);
      },
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

function getConnectedCloudAccounts(cloudType) {
  return new Promise((resolve, reject) => {
    let url = "/cloudscanner/list";
    if (cloudType) {
      url = `${url}?cloudType=${cloudType}`;
    }

    sendRequest(url, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function deleteConnectedCloudAccount(accountID) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/${accountID}`, "delete", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function startCloudAccountScan(accountID) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/scan`, "post", { accountID }, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function connectCloudAccount(account) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/connect`, "post", account, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getResourcesProgress(aid) {
  let params = {};
  if (aid) {
    params.aid = aid;
  }
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/progress`, "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getResourceProgressDetails(aid, status, offset, limit) {
  let params = {};
  params.aid = aid;
  params.workStatus = status;
  params.offset = offset;
  params.limit = limit;
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/progressdetails`, "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getFrameworks(query) {
  if (!query) {
    query = null;
  }
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudposture/summary`, "get", { query }, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getFrameworkDetails(framework, version, platform, filters) {
  let details = {
    framework: framework,
    version: version,
    platforms: platform,
    ...filters
  };
  let params = {};
  params.query = JSON.stringify(details);
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudposture/report`, "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getFailedResources(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudposture/resources`, "get", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

function getCSPMAccounts() {
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/cspmAccounts`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getAWSStatus(url) {
  try {
    let results = await axios.get(url);
    return results.data;
  } catch (e) {
    throw e;
  }
}

async function postUserSignupHubSpot(url, body) {
  try {
    let response = await axios.post(url, body);
    return response;
  } catch (e) {
    throw e;
  }
}

async function startResourceScan(params) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/scan`, "post", params, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getRuleDetails(ruleID) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/cloudposture/ruledetails?ruleID=${ruleID}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function makeAxiosCall(url, headers, noServerURL = false) {
  let urlHeader = {};
  let config = await getConfig();
  if (!noServerURL) {
    url = `${config.SERVER_URL}${url}`;
  }
  headers = Object.assign(
    {},
    {
      url,
      headers: urlHeader,
      withCredentials: true
    },
    headers
  );

  return axios(headers)
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

async function makeNGAuthCall(url, headers) {
  let config = await getConfig();
  url = `${config.NG_AUTH_SERVER_URL}${url}`;
  headers = Object.assign(
    {},
    {
      url,
      withCredentials: true
    },
    headers
  );

  return axios(headers)
    .then(response => {
      return response.data;
    })
    .catch(e => {
      throw e;
    });
}

async function getReportsCSV(filters) {
  let query = {
    query: JSON.stringify(filters)
  };

  return makeAxiosCall(`/reports/resourceslist?query=${query.query}`, {
    method: "GET",
    responseType: "blob"
  })
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

async function getReportJSON(url) {
  return makeAxiosCall(
    `${url}`,
    {
      method: "GET"
    },
    true
  )
    .then(response => {
      return response.data;
    })
    .catch(e => {
      throw e;
    });
}

async function getComplianceFullCSV(filters) {
  let query = {
    query: JSON.stringify(filters)
  };
  return makeAxiosCall(
    `/reports/compliancedetailedreport?query=${query.query}`,
    {
      method: "GET",
      responseType: "blob"
    }
  )
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

async function getFailedRulesJSON(framework, version, accountID) {
  let filters = {
    framework: framework,
    version: version,
  };
  let params = {
    query: JSON.stringify(filters)
  };
  return makeAxiosCall(
    `/remediations/failedresourcesjson?query=${params.query}&accountID=${accountID}`,
    {
      method: "GET",
      responseType: "blob"
    }
  )
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

async function getDynamicFilters(query) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/asset/filters?query=${JSON.stringify(query)}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function getResourcesTreeData(selection) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/asset/treemap?query=${JSON.stringify(selection)}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function getKubernetesClusters(isManaged = false) {
  return new Promise((resolve, reject) => {
    sendRequest(
      `/kubernetes/clusters?isManaged=${isManaged}`,
      "get",
      {},
      resolve,
      {
        requiresAuth: true,
        errorCallback: reject
      }
    );
  });
}

async function getClusterMultiOnboardScript() {
  return new Promise((resolve, reject) => {
    sendRequest(`/kubernetes/multionboarding`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getToxicCombinations(filter) {
  let query = {}
  if(filter) {
    query.query = filter
  }
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/toxic`, "get", query, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getRemediateCommands() {
  return new Promise((resolve, reject) => {
    sendRequest(`/remediations/commands`, "get", {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getResourcesOverview(params) {
  let query = {query: JSON.stringify(params)};
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/resourcecounts`, "get", query, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function setHelpRequest(params) {
  return new Promise((resolve, reject) => {
    sendRequest("/help", "post", params || {}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getUserConfig() {
  return new Promise((resolve, reject) => {
    sendRequest("/user/dashboardconfigs", "get", null, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function setUserConfig(config) {
  let query = JSON.stringify(config);
  return new Promise((resolve, reject) => {
    sendRequest("/user/dashboardconfigs", "put", {query}, resolve, {
      requiresAuth: true,
      errorCallback: reject,
      useQueryParams: true
    });
  });
}

async function getEmergingThreats(count) {
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/widget/emerging/${count}`, "get", null, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getUserNotifications() {
  return new Promise((resolve, reject) => {
    sendRequest(`/notifications`, "get", null, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function triggerDiscovery(accountID) {
  return new Promise((resolve, reject) => {
    sendRequest(`/cloudscanner/scan`, "post", {accountID: accountID, workItemID: "automation"}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function upgradeAccount(query) {
  return new Promise((resolve, reject) => {
    sendRequest(`/tenant/upgrade`, "put", query, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getTenantsList() {
  return makeNGAuthCall(
    `/tenants`,
    {
      method: "GET"
    }
  )
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

async function getSecurityPatches(aid) {
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/${aid}/secpatches`, "get", null, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getVulnerabilities(aid) {
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/${aid}/cpes`, "get", null, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getSoftwareCVEs(aid, cpeid, query) {
  query = JSON.stringify(query);
  return new Promise((resolve, reject) => {
    sendRequest(`/asset/${aid}/cpes/${cpeid}`, "get", {query}, resolve, {
      requiresAuth: true,
      errorCallback: reject
    });
  });
}

async function getReportData(link) {
  debugger
  return makeAxiosCall(
    link,
    {
      method: "GET",
      responseType: "blob"
    },
    true
  )
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

const APIHandler = {
  createAccount,
  getInviteDetails,
  prepareQueryParams,
  getDefinitions,
  getDefinitionsFilters,
  getWordCloud,
  createCredentials,
  getCredentials,
  revokeCredentials,
  updateCredentialsLabel,
  cancelCurrentActiveRequests,
  getCves,
  getCveFilters,
  getCveCategory,
  getCveVersion,
  getCveProblem,
  getCveSunburstVPData,
  getCveSunburstPVData,
  getCveStackedBarData,
  CVE_FILTERS_SELECT,
  DEFINITIONS_FILTERS_SELECT,
  getDefinitionsSunburstFirstLevel,
  getDefinitionsSunburstSecondLevel,
  getCveDetails,
  getDefinitionDetails,
  parseInteractiveReport,
  downloadOVALReport,
  downloadXCCDFReport,
  createOrganization,
  updateOrganization,
  getOrganization,
  getCredentialDetails,
  getComplianceBenchmarks,
  getBenchmarks,
  getBenchmark,
  addBenchmark,
  updateBenchmark,
  deleteBenchmark,
  getBenchmarkProfiles,
  getChecksOfProfile,
  addCheckToProfile,
  deleteCheckFromProfile,
  getConfig,
  getFreeTenantId,
  forgotTenant,
  addCompartment,
  moveCompartment,
  deleteCompartment,
  updateCompartment,
  getCompartments,
  getUserAccount,
  inviteUsers,
  getTenantName,
  getUsers,
  updateUserPrivileges,
  deleteUser,
  validateInvite,
  updateUserCompartments,
  getChecks,
  addCheck,
  updateCheck,
  deleteCheck,
  verifyEmail,
  forgotPassword,
  sendEmailVerification,
  getTenantDetails,
  getAssets,
  getReportURL,
  getAWSSetupURL,
  getAzureSetupURL,
  getGcpSetupURL,
  getAzureResourceTemplate,
  getConnectedCloudAccounts,
  deleteConnectedCloudAccount,
  connectCloudAccount,
  startCloudAccountScan,
  getAssetDetails,
  getAssessments,
  getAssetChartData,
  getResourcesProgress,
  moveAssetsToCompartment,
  deleteAssets,
  getAWSStatus,
  getResourceProgressDetails,
  getConnectedAccounts,
  getResourcesSummary,
  getSeveritySummary,
  getRiskSummary,
  getFrameworks,
  getFrameworkDetails,
  getFailedResources,
  getCSPMAccounts,
  postUserSignupHubSpot,
  getAzureStatus,
  getGcpStatus,
  startResourceScan,
  getRuleDetails,
  getReportsCSV,
  getComplianceFullCSV,
  getDynamicFilters,
  getResourcesTreeData,
  getUpdateURL,
  setUpdatedStack,
  getKubernetesClusters,
  getClusterMultiOnboardScript,
  getReportJSON,
  getToxicCombinations,
  getFailedRulesJSON,
  getRemediateCommands,
  setHelpRequest,
  getUserConfig,
  setUserConfig,
  getResourcesOverview,
  getEmergingThreats,
  getCriticalAlerts,
  getUserNotifications,
  triggerDiscovery,
  upgradeAccount,
  getTenantsList,
  getSecurityPatches,
  getVulnerabilities,
  getSoftwareCVEs,
  getReportData
};

export default APIHandler;
